var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useAuth0 } from '@/lib/auth0';
import AppWrapper from '@/presentation/main/app-wrapper';
import OverlayBouncyLoader from '@/presentation/components/loader/overlay-bouncy-loader';
const LogoutWrapper = ({ appProps }) => {
    const { logout } = useAuth0();
    // as soon as logout url is hit, send them to logout
    // as the user might be trying to force logout
    if (window.location.pathname === '/logout') {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield logout();
        }))();
        return React.createElement(OverlayBouncyLoader, null);
    }
    return React.createElement(AppWrapper, { appProps: appProps });
};
export default LogoutWrapper;
