import React, { useEffect, useState } from 'react';
import { COLUMN_WIDTH_STORAGE_KEY, PREFERENCES_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import { RulesLibraryListColumnDefinitions, RulesLibraryListPropertyFilterFilteringProperties, RulesLibraryListTablePreferences, RulesLibraryListTableVisibleContentOptions, RulesListTablePageSizeOptions } from '@/presentation/pages/rules/rules-list-config';
import { paginationLabels } from '@/presentation/components/commons/labels';
import { getInspectionColor, makePropertyFilterI18nStrings, makeSplitPanelI18nStrings } from '@/presentation/pages';
import { Alert, Badge, Box, Button, CollectionPreferences, ColumnLayout, ExpandableSection, Flashbar, Modal, Pagination, PropertyFilter, SegmentedControl, SpaceBetween, SplitPanel, Table } from '@cloudscape-design/components-themed/components';
import { useRulesHandlers } from '@/presentation/providers';
import { ServiceInspections } from '@/presentation/handlers';
import { TableEmptyState, TableHeader, TableNoMatchState } from '@/presentation/components/commons/common-components';
import { KiverascapeLayout } from '@/presentation/layouts/kiverascape-layout';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import InspectionModal from '@/presentation/components/modal/inspection-modal';
import InformationModal from '@/presentation/components/modal/information-modal';
import { getRandomUUID } from '@/lib/misc/utils';
import { useAuth0 } from '@/lib/auth0';
import { getAllowedRolesFromToken } from '@/lib/auth0/utils';
import { ROLETYPE } from '@/presentation/pages/settings/settings-types';
import { useSplitPanel } from '@/presentation/components/commons/use-split-panel';
import { getRulesLibraryListSplitPanel } from '@/presentation/pages/rules/rules-library/rules-library-list-split-panel';
import ContentWrapper from '@/presentation/components/content-wrapper';
import TableContainer from '@/presentation/components/table/table-container';
import { getFilterCounterText } from '@/presentation/components/commons/tableCounterStrings';
import { useColumnWidths } from '@/presentation/components/commons/use-column-widths';
import { useLocalStorage } from '@/presentation/components/commons/localStorage';
import { useCollection } from '@cloudscape-design/collection-hooks';
import DuplicateModal from "@/presentation/pages/rules/rules-library/rules-library-duplicate-modal";
import OverlayBouncyLoader from "@/presentation/components/loader/overlay-bouncy-loader";
import ExternalLink from '@/presentation/components/links/external-link';
import { getUrlParamsName } from '@/presentation/common-utils/common-utils';
import { PATH_DOCUMENTATION, PATH_RULES, PATHS } from '@/presentation/common-utils/constants';
import { useHistory } from '@/lib/browser-history/history-provider';
const RulesLibrary = (props) => {
    var _a;
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const { rawToken } = useAuth0();
    const isUserAdmin = getAllowedRolesFromToken(rawToken).includes(ROLETYPE.ADMIN);
    const { rules } = useRulesHandlers();
    const rulesHandler = rules;
    const tableEmptyState = React.createElement(TableEmptyState, { resourceName: 'Rules' });
    const [managedRules, setManagedRules] = useState(props.managedRules);
    const referrer = getUrlParamsName('referrer');
    const docsReferrer = referrer || window.location.pathname;
    const documentPath = `${PATH_DOCUMENTATION.RULES_LIBRARY}?referrer=${docsReferrer}`;
    const selectedRuleOption = (_a = managedRules === null || managedRules === void 0 ? void 0 : managedRules.filter(rule => (rule === null || rule === void 0 ? void 0 : rule.id) === (props === null || props === void 0 ? void 0 : props.selectedRule))) !== null && _a !== void 0 ? _a : [];
    const [selectedItems, setSelectedItems] = useState(selectedRuleOption);
    const [uniqueRules, setUniqueRules] = useState([]);
    const { notifications } = useNotifications();
    const [providerSegmentFilterSelectedID, setProviderSegmentFilterSelectedID] = useState('ALL');
    const [splitOpen, setSplitOpen] = useState(false);
    const [splitKeyValue, setSplitKeyValue] = useState(1);
    const onSplitPanelToggle = ({ detail: { open } }) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSplitOpen(open);
    };
    const handleCopy = (item) => {
        setSplitKeyValue(splitKeyValue + 1);
        setSelectedItems([item]);
        setSplitOpen(true);
    };
    const [renderCount, setRenderCount] = useState(1);
    const updatedColumnDefinitions = RulesLibraryListColumnDefinitions(handleCopy);
    const [columnDefinitions] = useColumnWidths(COLUMN_WIDTH_STORAGE_KEY.RULES_LIBRARY_LIST, updatedColumnDefinitions);
    const [preferences, setPreferences] = useLocalStorage(PREFERENCES_STORAGE_KEY.RULES_LIBRARY_LIST, RulesLibraryListTablePreferences);
    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(managedRules, {
        propertyFiltering: {
            filteringProperties: RulesLibraryListPropertyFilterFilteringProperties,
            empty: tableEmptyState,
            noMatch: React.createElement(TableNoMatchState, { resourceName: 'rules' })
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: { sortingColumn: updatedColumnDefinitions === null || updatedColumnDefinitions === void 0 ? void 0 : updatedColumnDefinitions[0], isDescending: true } },
        selection: {}
    });
    const [panelPreferences, setPanelPreferences] = useState({ position: 'side' });
    const { header: panelHeader, body: panelBody } = getRulesLibraryListSplitPanel(selectedItems, rulesHandler);
    const { splitPanelSize, onSplitPanelResize } = useSplitPanel(collectionProps.selectedItems, splitOpen, onSplitPanelToggle);
    const handleOnChangePanelPreferences = (preference) => {
        if (preference === null || preference === void 0 ? void 0 : preference.detail) {
            setPanelPreferences(preference.detail);
        }
    };
    const handleSplitPanelResize = (evt) => {
        var _a;
        setSplitKeyValue(splitKeyValue + (((_a = evt === null || evt === void 0 ? void 0 : evt.detail) === null || _a === void 0 ? void 0 : _a.size) || 1));
        onSplitPanelResize(evt);
    };
    const [showInspectionModal, setShowInspectionModal] = useState(false);
    const onInspectionDiscard = () => setShowInspectionModal(false);
    const [showInformationModal, setShowInformationModal] = useState(false);
    const onInformationDiscard = () => setShowInformationModal(false);
    const [toBeEnabledServiceRules, setToBeEnabledServiceRules] = useState([]);
    const [duplicateRules, setDuplicateRules] = useState([]);
    const [showDuplicationModal, setShowDuplicationModal] = useState(false);
    const onDuplicationDiscard = () => setShowDuplicationModal(false);
    const onDiscard = () => {
        props.onDiscard();
    };
    const { history } = useHistory();
    const browserHistory = history;
    useEffect(() => {
        setManagedRules(props.managedRules);
    }, [props.managedRules]);
    const getUniqueGlobalServiceRules = (rules) => {
        const uniqueRules = [];
        const uniqueGlobalServices = [];
        rules.forEach(rule => {
            if (!uniqueGlobalServices.includes(rule.globalServiceID)) {
                uniqueGlobalServices.push(rule.globalServiceID);
                uniqueRules.push(rule);
            }
        });
        return uniqueRules;
    };
    const checkDuplicatesAndImport = () => {
        setIsOverlayLoading(true);
        const importedIDs = selectedItems.map(item => item.id);
        rulesHandler.getRulesByImportedFromIDs({
            imported_from: importedIDs
        }).then(result => {
            var _a, _b;
            if (((_a = result === null || result === void 0 ? void 0 : result.Rules) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                const dupRuleIDs = (_b = result === null || result === void 0 ? void 0 : result.Rules) === null || _b === void 0 ? void 0 : _b.map(rule => rule === null || rule === void 0 ? void 0 : rule.imported_from);
                const dupRules = selectedItems.filter(item => dupRuleIDs.includes(item.id));
                setDuplicateRules(dupRules);
                setShowDuplicationModal(true);
            }
            else {
                setDuplicateRules([]);
                checkAndImportRules();
            }
        }).catch(console.error)
            .finally(() => setIsOverlayLoading(false));
    };
    const checkAndImportRules = () => {
        setShowDuplicationModal(false);
        // Either the Service is Disabled or the Service Mapping is not present
        const unImportableRules = selectedItems.filter(rule => rule.inspection === ServiceInspections.DISABLED);
        // If the user is not Admin and Services need to be enabled, show the InformationModal
        if (unImportableRules.length > 0 && !isUserAdmin) {
            setToBeEnabledServiceRules([]);
            setShowInformationModal(true);
            return;
        }
        const uniqueRules = getUniqueGlobalServiceRules(unImportableRules);
        setToBeEnabledServiceRules(uniqueRules);
        if (unImportableRules.length > 0) {
            setShowInspectionModal(true);
        }
        else {
            props.onImport([], selectedItems);
        }
    };
    const enableServicesAndImportRules = () => {
        props.onImport(toBeEnabledServiceRules, selectedItems);
    };
    const makeProviderSegmentOptions = () => {
        var _a;
        const allOption = { text: 'All', id: 'ALL' };
        const restOfOptions = (_a = props.providerFilterFilteringOptions) === null || _a === void 0 ? void 0 : _a.map(provider => {
            return {
                text: provider === null || provider === void 0 ? void 0 : provider.value,
                id: provider === null || provider === void 0 ? void 0 : provider.value
            };
        });
        return [allOption, ...restOfOptions];
    };
    const handleProviderSegmentedFiltersChange = ({ detail }) => {
        var _a;
        setProviderSegmentFilterSelectedID(detail.selectedId);
        if (detail.selectedId === 'ALL') {
            setManagedRules(props === null || props === void 0 ? void 0 : props.managedRules);
        }
        else {
            const filteredRules = (_a = props.managedRules) === null || _a === void 0 ? void 0 : _a.filter(rule => (rule === null || rule === void 0 ? void 0 : rule.provider) === detail.selectedId);
            setManagedRules(filteredRules);
        }
    };
    const providerSegmentedFilters = () => {
        return React.createElement("div", { className: 'rules-library-provider-segment-filter' },
            React.createElement(SegmentedControl, { selectedId: providerSegmentFilterSelectedID, onChange: handleProviderSegmentedFiltersChange, label: "providers", options: makeProviderSegmentOptions() }));
    };
    useEffect(() => {
        const handleWindowResize = () => {
            document.body.style.overflowY = "hidden";
        };
        window.addEventListener('resize', handleWindowResize);
        browserHistory.push(PATH_RULES.LIBRARY.toString());
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            document.body.style.overflowY = "auto";
            browserHistory.push(PATHS.RULES);
        };
    }, []);
    return React.createElement(React.Fragment, null,
        React.createElement(Modal, { visible: true, onDismiss: onDiscard, closeAriaLabel: "Close dialog", size: 'max', header: React.createElement("div", { className: "rules-library-header" },
                React.createElement(TableHeader, Object.assign({ variant: 'awsui-h1-sticky', title: 'Rules Library', info: React.createElement("span", null,
                        React.createElement("span", { className: "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium rules-library-info-badge" }, "BETA"),
                        React.createElement("span", { className: "inline-flex items-center px-2 py-1 text-xs font-medium" },
                            React.createElement("span", null, "Learn more about the Kivera Rules Library"),
                            React.createElement("span", { className: "px-1 kivera-external-link-xs" },
                                React.createElement(ExternalLink, { to: documentPath, label: "here", variant: "primary" })))), selectedItems: selectedItems, totalItems: managedRules }, props))), footer: React.createElement(Box, { float: "right" },
                React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { variant: "link", onClick: onDiscard }, "Cancel"),
                    React.createElement(Button, { variant: "primary", disabled: props.disableImport || selectedItems.length < 1, onClick: checkDuplicatesAndImport }, "Import Rules"))), modalRoot: document.getElementById('rules-library-container') },
            React.createElement(KiverascapeLayout, { notifications: React.createElement(Flashbar, { items: notifications, stackItems: true }), splitPanelOpen: splitOpen, onSplitPanelToggle: onSplitPanelToggle, splitPanelSize: splitPanelSize, onSplitPanelResize: handleSplitPanelResize, splitPanelPreferences: panelPreferences, onSplitPanelPreferencesChange: handleOnChangePanelPreferences, splitPanel: (panelBody && selectedItems.length > 0)
                    ? (React.createElement(SplitPanel, { header: panelHeader, i18nStrings: makeSplitPanelI18nStrings() }, panelBody))
                    : null, content: React.createElement("div", { className: "rules-library-list-items" },
                    React.createElement(ContentWrapper, { testid: "rules-library-list-items" },
                        React.createElement(TableContainer, { key: renderCount },
                            React.createElement(Table, Object.assign({}, collectionProps, { key: splitKeyValue, resizableColumns: true, loading: props.dataLoading, loadingText: 'Loading Rules Library...', selectionType: 'multi', columnDefinitions: columnDefinitions, visibleColumns: preferences.visibleContent, items: items, ariaLabels: {}, variant: "full-page", wrapLines: preferences.wrapLines, selectedItems: selectedItems, onSelectionChange: event => {
                                    setSelectedItems(event.detail.selectedItems);
                                    setUniqueRules(getUniqueGlobalServiceRules(event.detail.selectedItems));
                                    setSplitKeyValue(splitKeyValue + 1);
                                }, filter: React.createElement(SpaceBetween, { size: 'm', direction: 'horizontal' },
                                    providerSegmentedFilters(),
                                    React.createElement("div", { className: 'list-property-filter-rules-library' },
                                        React.createElement(PropertyFilter, Object.assign({}, propertyFilterProps, { i18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Rules' }), countText: getFilterCounterText(filteredItemsCount), expandToViewport: true, filteringOptions: props.propertyFilterFilteringOptions, filteringProperties: RulesLibraryListPropertyFilterFilteringProperties })))), pagination: React.createElement(Pagination, Object.assign({}, paginationProps, { ariaLabels: paginationLabels })), preferences: React.createElement(CollectionPreferences, { title: "Preferences", confirmLabel: "Confirm", cancelLabel: "Cancel", disabled: false, preferences: preferences, onConfirm: ({ detail }) => {
                                        setPreferences(detail);
                                        setRenderCount(renderCount + 1);
                                    }, pageSizePreference: {
                                        title: 'Page Size',
                                        options: RulesListTablePageSizeOptions
                                    }, wrapLinesPreference: {
                                        label: 'Wrap Lines',
                                        description: 'If checked, lines will be wrapped to display all of the text'
                                    }, visibleContentPreference: {
                                        title: 'Visible Columns',
                                        options: RulesLibraryListTableVisibleContentOptions
                                    } }) }))),
                        React.createElement(InspectionModal, { visible: showInspectionModal, items: toBeEnabledServiceRules, onDiscard: onInspectionDiscard, onSubmit: enableServicesAndImportRules, header: 'Update Global Services', alertInfo: React.createElement(React.Fragment, null,
                                "Proceeding with this action will set all selected services to become ",
                                React.createElement(Badge, { color: getInspectionColor(ServiceInspections.ENABLED) }, ServiceInspections.ENABLED)), submitButton: 'Enable and Import' }),
                        React.createElement(DuplicateModal, { visible: showDuplicationModal, onDiscard: onDuplicationDiscard, onSubmit: checkAndImportRules, items: duplicateRules }),
                        React.createElement(InformationModal, { visible: showInformationModal, onDiscard: onInformationDiscard, discardButtonText: 'Close', header: 'Contact Admin', info: React.createElement(SpaceBetween, { size: "m" },
                                React.createElement(Alert, { type: 'warning' }, "The selected services need to Enabled before proceeding. Please contact Admin to enable these services."),
                                React.createElement(ColumnLayout, { columns: 1 }, uniqueRules.length <= 5
                                    ? React.createElement(React.Fragment, null, React.createElement("ul", null, uniqueRules === null || uniqueRules === void 0 ? void 0 : uniqueRules.map(item => {
                                        return React.createElement("li", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.service);
                                    })))
                                    : React.createElement(ExpandableSection, { headerText: "Services" }, React.createElement("ul", null, uniqueRules === null || uniqueRules === void 0 ? void 0 : uniqueRules.map(item => {
                                        return React.createElement("li", { key: getRandomUUID() }, item === null || item === void 0 ? void 0 : item.service);
                                    }))))) }))), contentType: "default" })),
        isOverlayLoading && React.createElement(OverlayBouncyLoader, null));
};
export default RulesLibrary;
