import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardFactory from '@/presentation/main/factories/pages/dashboard/dashboard-factory';
import ReportingFactory from '@/presentation/main/factories/pages/reporting/reporting-factory';
import MakeProxiesList from '@/presentation/main/factories/pages/proxies/proxies-factory-list';
import MakeProxiesWizard from '@/presentation/main/factories/pages/proxies/proxies-factory-wizard';
import MakeProxiesView from '@/presentation/main/factories/pages/proxies/proxies-factory-view';
import { useAuth0 } from '@/lib/auth0';
import MakeProxiesDeployments from '@/presentation/main/factories/pages/proxies/proxies-factory-deployments';
import MakeSettingsPage from '@/presentation/main/factories/pages/settings/settings-factory';
import MakeMyProfilePage from '@/presentation/main/factories/pages/my-profile/my-profile-factory';
import MakeProfilesList from '@/presentation/main/factories/pages/profiles/profiles-factory-list';
import MakeProfilesView from '@/presentation/main/factories/pages/profiles/profiles-factory-view';
import MakeProfilesFormWrapper from '@/presentation/main/factories/pages/profiles/profiles-factory-form';
import MakeRulesList from '@/presentation/main/factories/pages/rules/rules-factory-list';
import MakeRulesWrapper from '@/presentation/main/factories/pages/rules/rules-factory-wrapper';
import MakeIdentitiesList from '@/presentation/main/factories/pages/identities/identities-factory-list';
import MakeIdentitiesView from '@/presentation/main/factories/pages/identities/identities-factory-view';
import OopsErrorPage from '@/presentation/pages/errors/oops-error-page';
import MakeIdentitiesWizard from '@/presentation/main/factories/pages/identities/identities-factory-wizard';
import MakeMonitoringList from '@/presentation/main/factories/pages/monitoring/monitoring-factory-list';
import MakeSettingsOrgPolicyFuncsPage from '@/presentation/main/factories/pages/settings/settings-org-policy-funcs-factory';
import MakeSettingsEditPage from '@/presentation/main/factories/pages/settings/settings-factory-edit';
import MakeRulesSimulatorList from "@/presentation/main/factories/pages/rules/rules-simulator-list";
import OnboardingFactory from "@/presentation/main/factories/pages/onboarding/onboarding-factory";
import { PATHS, PATH_SETTINGS } from '../common-utils/constants';
import MakeSettingsCloudTenantPage from "@/presentation/main/factories/pages/settings/settings-cloud-tenant-factory";
import WelcomeToKivera from "@/presentation/pages/welcome";
import RouteWrapper from './route-wrapper';
import Support from '../components/support/support';
import Documentation from '../components/docs/docs';
import NavigationRouter from './navigation-router';
import MakeSettingsDomainACLPage from '@/presentation/main/factories/pages/settings/settings-domain-acls-factory';
const AppRouter = () => {
    const { user } = useAuth0();
    const userDetails = user;
    if (!(userDetails === null || userDetails === void 0 ? void 0 : userDetails.verified)) {
        if (window.location.pathname !== PATHS.ONBOARDING) {
            window.location.replace(PATHS.ONBOARDING);
        }
        return (React.createElement(Routes, null,
            React.createElement(Route, { path: "/onboarding", element: React.createElement(OnboardingFactory, null) })));
    }
    else if (window.location.pathname === PATHS.ONBOARDING) {
        window.location.replace(PATHS.DASHBOARD);
    }
    return (React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(NavigationRouter, null) },
            React.createElement(Route, { path: "/", element: React.createElement(DashboardFactory, null) }),
            React.createElement(Route, { path: "/welcome", element: React.createElement(RouteWrapper, { path: "/" },
                    React.createElement(WelcomeToKivera, null)) }),
            React.createElement(Route, { path: "/proxies", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeProxiesList, null)) }),
            React.createElement(Route, { path: "/proxies/create", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeProxiesWizard, { isCreate: true, isEdit: false, isClone: false })) }),
            React.createElement(Route, { path: "/proxies/:proxyID/clone", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeProxiesWizard, { isCreate: false, isEdit: false, isClone: true })) }),
            React.createElement(Route, { path: "/proxies/:proxyID/edit", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeProxiesWizard, { isCreate: false, isEdit: true, isClone: false })) }),
            React.createElement(Route, { path: "/proxies/:proxyID", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeProxiesView, null)) }),
            React.createElement(Route, { path: "/proxies/:proxyID/deployments/create", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeProxiesDeployments, { isCreate: true, isEdit: false, isClone: false })) }),
            React.createElement(Route, { path: "/proxies/:proxyID/identity/:identityID", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeIdentitiesView, null)) }),
            React.createElement(Route, { path: "/proxies/:proxyID/identity/:identityID/profile/:profileID", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeProfilesView, { isCreate: false, isEdit: false, isClone: false, isView: true })) }),
            React.createElement(Route, { path: "/proxies/:proxyID/identity/:identityID/profile/:profileID/rule/:ruleID", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeRulesWrapper, { isCreate: false, isEdit: false, isClone: false, isView: true })) }),
            React.createElement(Route, { path: "/proxies/:proxyID/identity/:identityID/profile/:profileID/rule/:ruleID/simulator", element: React.createElement(RouteWrapper, { path: PATHS.PROXIES },
                    React.createElement(MakeRulesSimulatorList, null)) }),
            React.createElement(Route, { path: "/identities", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeIdentitiesList, null)) }),
            React.createElement(Route, { path: "/identities/:identityID", element: React.createElement(RouteWrapper, { path: PATHS.IDENTITIES },
                    React.createElement(MakeIdentitiesView, null)) }),
            React.createElement(Route, { path: "/identities/create", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeIdentitiesWizard, { isCreate: true, isEdit: false, isClone: false })) }),
            React.createElement(Route, { path: "/identities/:identityID/clone", element: React.createElement(RouteWrapper, { path: PATHS.IDENTITIES },
                    React.createElement(MakeIdentitiesWizard, { isCreate: false, isEdit: false, isClone: true })) }),
            React.createElement(Route, { path: "/identities/:identityID/edit", element: React.createElement(RouteWrapper, { path: PATHS.IDENTITIES },
                    React.createElement(MakeIdentitiesWizard, { isCreate: false, isEdit: true, isClone: false })) }),
            React.createElement(Route, { path: "/identities/:identityID/profile/:profileID", element: React.createElement(RouteWrapper, { path: PATHS.IDENTITIES },
                    React.createElement(MakeProfilesView, { isCreate: false, isEdit: false, isClone: false, isView: true })) }),
            React.createElement(Route, { path: "/identities/:identityID/profile/:profileID/rule/:ruleID", element: React.createElement(RouteWrapper, { path: PATHS.IDENTITIES },
                    React.createElement(MakeRulesWrapper, { isCreate: false, isEdit: false, isClone: false, isView: true })) }),
            React.createElement(Route, { path: "/identities/:identityID/profile/:profileID/rule/:ruleID/simulator", element: React.createElement(RouteWrapper, { path: PATHS.IDENTITIES },
                    React.createElement(MakeRulesSimulatorList, null)) }),
            React.createElement(Route, { path: "/profiles", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeProfilesList, null)) }),
            React.createElement(Route, { path: "/profiles/create", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeProfilesFormWrapper, { isCreate: true, isEdit: false, isClone: false, isView: false })) }),
            React.createElement(Route, { path: "/profiles/:profileID/clone", element: React.createElement(RouteWrapper, { path: PATHS.PROFILES },
                    React.createElement(MakeProfilesFormWrapper, { isCreate: false, isEdit: false, isClone: true, isView: false })) }),
            React.createElement(Route, { path: "/profiles/:profileID/edit", element: React.createElement(RouteWrapper, { path: PATHS.PROFILES },
                    React.createElement(MakeProfilesFormWrapper, { isCreate: false, isEdit: true, isClone: false, isView: false })) }),
            React.createElement(Route, { path: "/profiles/:profileID", element: React.createElement(RouteWrapper, { path: PATHS.PROFILES },
                    React.createElement(MakeProfilesView, { isCreate: false, isEdit: false, isClone: false, isView: true })) }),
            React.createElement(Route, { path: "/profiles/:profileID/rule/:ruleID", element: React.createElement(RouteWrapper, { path: PATHS.PROFILES },
                    React.createElement(MakeRulesWrapper, { isCreate: false, isEdit: false, isClone: false, isView: true })) }),
            React.createElement(Route, { path: "/profiles/:profileID/rule/:ruleID/simulator", element: React.createElement(RouteWrapper, { path: PATHS.PROFILES },
                    React.createElement(MakeRulesSimulatorList, null)) }),
            React.createElement(Route, { path: "/rules", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeRulesList, { openLibrary: false })) }),
            React.createElement(Route, { path: "/rules/library", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeRulesList, { openLibrary: true })) }),
            React.createElement(Route, { path: "/rules/create", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeRulesWrapper, { isCreate: true, isEdit: false, isClone: false, isView: false })) }),
            React.createElement(Route, { path: "/rules/:ruleID/clone", element: React.createElement(RouteWrapper, { path: PATHS.RULES },
                    React.createElement(MakeRulesWrapper, { isCreate: false, isEdit: false, isClone: true, isView: false })) }),
            React.createElement(Route, { path: "/rules/:ruleID/edit", element: React.createElement(RouteWrapper, { path: PATHS.RULES },
                    React.createElement(MakeRulesWrapper, { isCreate: false, isEdit: true, isClone: false, isView: false })) }),
            React.createElement(Route, { path: "/rules/:ruleID", element: React.createElement(RouteWrapper, { path: PATHS.RULES },
                    React.createElement(MakeRulesWrapper, { isCreate: false, isEdit: false, isClone: false, isView: true })) }),
            React.createElement(Route, { path: "/rules/:ruleID/simulator", element: React.createElement(RouteWrapper, { path: PATHS.RULES },
                    React.createElement(MakeRulesSimulatorList, null)) }),
            React.createElement(Route, { path: "/monitoring", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeMonitoringList, null)) }),
            React.createElement(Route, { path: "/my-profile", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeMyProfilePage, null)) }),
            React.createElement(Route, { path: "/settings", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeSettingsPage, null)) }),
            React.createElement(Route, { path: "/settings/edit", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeSettingsEditPage, null)) }),
            React.createElement(Route, { path: "/settings/:tabId?/:id", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeSettingsPage, null)) }),
            React.createElement(Route, { path: "/settings/org-policy-functions/edit", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeSettingsOrgPolicyFuncsPage, null)) }),
            React.createElement(Route, { path: "/settings/cloud-tenant-structures/create", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeSettingsCloudTenantPage, { isCreate: true, isEdit: false })) }),
            React.createElement(Route, { path: "/settings/cloud-tenant-structures/:tenantID/edit", element: React.createElement(RouteWrapper, { path: `${PATH_SETTINGS.CLOUD_TENANTS}` },
                    React.createElement(MakeSettingsCloudTenantPage, { isCreate: false, isEdit: true })) }),
            React.createElement(Route, { path: "/settings/domain-acls/create", element: React.createElement(RouteWrapper, null,
                    React.createElement(MakeSettingsDomainACLPage, { isCreate: true, isEdit: false })) }),
            React.createElement(Route, { path: "/settings/domain-acls/:domainACLID/edit", element: React.createElement(RouteWrapper, { path: `${PATH_SETTINGS.DOMAIN_ACLS}` },
                    React.createElement(MakeSettingsDomainACLPage, { isCreate: false, isEdit: true })) }),
            React.createElement(Route, { path: "/reporting", element: React.createElement(RouteWrapper, null,
                    React.createElement(ReportingFactory, null)) }),
            React.createElement(Route, { path: "/oops", element: React.createElement(RouteWrapper, { path: "/" },
                    React.createElement(OopsErrorPage, null)) }),
            React.createElement(Route, { path: "/docs/*", element: React.createElement(RouteWrapper, { path: "/" },
                    React.createElement(Documentation, null)) }),
            React.createElement(Route, { path: "/support", element: React.createElement(RouteWrapper, { path: "/" },
                    React.createElement(Support, null)) }),
            React.createElement(Route, { path: "*", element: React.createElement(OopsErrorPage, null) }))));
};
export default AppRouter;
