import ReactDOM from 'react-dom';
import React, { StrictMode } from 'react';
import '@/presentation/styles/global.css';
import '@/presentation/styles/styles.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { Auth0Provider } from '@/lib/auth0';
import { createBrowserHistory } from "history";
import { HistoryProvider } from "@/lib/browser-history/history-provider";
import 'react-loading-skeleton/dist/skeleton.css';
import "@cloudscape-design/components-themed/styles/custom.scss";
import { disableReactDevTools } from "@/presentation/main/utils/utils";
import { datadogRum } from '@datadog/browser-rum';
import LogoutWrapper from '@/presentation/main/logout-wrapper';
if (`${process.env.NODE_ENV}` === 'production') {
    disableReactDevTools();
}
else {
    console.log('Looks like we are in development mode!');
}
const auth0ClientOptions = {
    domain: `${process.env.AUTH_DOMAIN}`,
    clientId: `${process.env.AUTH_CLIENTID}`,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: `${process.env.AUTH_AUDIENCE}`,
    },
    useRefreshTokens: false,
    cookieDomain: '.kivera.io',
    cacheLocation: 'memory'
};
const appConfig = {
    locale: `${process.env.APP_LOCALE}`
};
const appProps = {
    appConfig: appConfig,
};
const history = createBrowserHistory();
//  Currently testing this out for dev and stg only
if (`${process.env.KIVERA_ENV}` === 'dev' || `${process.env.KIVERA_ENV}` === 'stg') {
    datadogRum.init({
        applicationId: `${process.env.DATADOG_APPLICATION_ID}`,
        clientToken: `${process.env.DATADOG_CLIENT_TOKEN}`,
        site: 'datadoghq.com',
        service: `${process.env.DATADOG_SERVICE}`,
        env: `${process.env.DATADOG_ENV}`,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        // more information: https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum
        allowedTracingUrls: [(url) => url.startsWith('https://mgmt')]
    });
    datadogRum.startSessionReplayRecording();
}
ReactDOM.render((React.createElement(StrictMode, null,
    React.createElement(HistoryProvider, { history: history },
        React.createElement(Auth0Provider, { auth0Options: auth0ClientOptions },
            React.createElement(LogoutWrapper, { appProps: appProps }))))), document.getElementById('root'));
